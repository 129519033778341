<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary ">
                <v-toolbar-title class="mx-auto">{{
                  $t("Login")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div>
                  <v-img
                    class="mx-auto"
                    style="width: 150px; height: 150px"
                    src="/assets/logo.jpeg"
                  ></v-img>
                  <!-- <h2 class="text-center mb-3 clr-primary">{{$t('VShape')}}</h2> -->
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <button
                      :class="{ 'active-login': type == 1 }"
                      class="btn btn-gradient-1"
                      @click="setActive(1)"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        border: none;
                      "
                    >
                      <span class="mx-1">

                        <svg
                        width="32" height="32"
                          viewBox="0 0 640 512"
                        >
                          <path
                            d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                          />
                        </svg>

                        <span
                          class="mx-5"
                          style="position: relative; bottom: 5px"
                          >أدمن</span
                        >
                      </span>

                      <svg
                        v-if="type == 1"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#fff"
                          stroke="#000"
                          stroke-width="2"
                        />
                        <path
                          d="M7 12.5L10.2 16 17 9"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>

                  <div class="col-md-6">
                    <button
                      :class="{ 'active-login': type == 2 }"
                      class="btn btn-gradient-1"
                      @click="setActive(2)"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px;
                        border: none;
                      "
                    >
                      <span class="mx-1">
                        <svg width="32" height="32" viewBox="0 0 512 512">
                          <path
                            d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                          />
                        </svg>

                        <span
                          style="position: relative; bottom: 5px"
                          class="mx-5"
                          >سائق</span
                        >
                      </span>

                      <svg
                        v-if="type == 2"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#fff"
                          stroke="#000"
                          stroke-width="2"
                        />
                        <path
                          d="M7 12.5L10.2 16 17 9"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <v-form>
                  <!-- <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.type'),
                    }"
                  >
                    <h4 class="mb-2">
                      {{ $t("Type") }} <span class="required">*</span>
                    </h4>
                    <v-autocomplete
                      dense
                      class="mb-2"
                      outlined
                      name="type"
                      type="text"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Type')"
                      v-model="type"
                      hide-details
                      item-value="id"
                      item-text="name"
                      :items="typeList"
                    ></v-autocomplete>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.type')"
                    >
                      {{ errors.first("addEditValidation.type") }}
                    </div>
                  </div> -->

                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.username'),
                    }"
                  >
                    <h4 class="mb-2 mt-5">
                      {{ $t("UserName") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="username"
                      type="text"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('UserName')"
                      v-model="object.email"
                      hide-details
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.username')"
                    >
                      {{ errors.first("addEditValidation.username") }}
                    </div>
                  </div>

                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.password'),
                    }"
                  >
                    <h4 class="mb-2 mt-2">
                      {{ $t("Password") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="password"
                      :type="showPassword ? 'text' : 'password'"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Password')"
                      v-model="object.password"
                      hide-details
                      @keypress.enter.prevent="login"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'" 
                      @click:append="togglePasswordVisibility" 
                      :clearable="false"
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.password')"
                    >
                      {{ errors.first("addEditValidation.password") }}
                    </div>
                  </div>


                </v-form>
              </v-card-text>
              <v-card-actions class="py-0 d-flex justify-center">
                <v-btn
                  class="mb-3"
                  small
                  outlined
                  width="160"
                  color="info"
                  @click="login"
                  >{{ $t("Login") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    type: 1,
    typeList: [],
    object: {
      email: null,
      password: null,
    },
    showPassword: false, // New data property to track password visibility
  }),
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Toggle password visibility
    },
    setActive(type) {
      this.type = type;
    },
    login() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (valid) {
          if (this.type == 1) {
            this.$store.dispatch("auth/login", this.object).then((result) => {
              this.notifySuccess("Login", result);
              this.loginSuccess(result);
              window.location.href = "/admin/home";
            });
          } else if (this.type == 2) {
            this.$store
              .dispatch("auth/loginDriver", this.object)
              .then((result) => {
                this.notifySuccess("Login", result);

                localStorage.removeItem("event_date");
                localStorage.removeItem("event_branch_id");
                localStorage.removeItem("event_period_id");

                localStorage.removeItem("home_city_id");
                localStorage.removeItem("home_date");
                localStorage.removeItem("home_period_id");
                localStorage.removeItem("home_details_neighborhood_id");
                localStorage.removeItem("customer_reading_id");

                window.location.href = "/driver/home";
                this.loginDriverSuccess(result);
              });
          }
        } else {
          let result = {
            response: {
              data: {
                errors: this.$t("MissedData"),
              },
            },
          };
          this.notifyError("Login", result);
        }
      });
    },
  },
  mounted() {
    this.typeList = [
      {
        id: 1,
        name: this.$t("TypeUser"),
      },
      {
        id: 2,
        name: this.$t("TypeDriver"),
      },
    ];
  },
};
</script>


<style>
.btn-gradient-1 {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  background: #fff;
  color: #000;
  border: 1px solid #000 !important;
}

svg path {
  fill: #000 !important;
}

.active-login {
  background: linear-gradient(to right, #4b6cb7, #182848);
  color: white;
}

.active-login svg path {
  fill: #fff !important;
}

.active-login .fa-check {
  display: inline-block;
}

.fa-check {
  display: none;
}
</style>